import { Component } from '@angular/core';
import ValidationEngine from 'devextreme/ui/validation_engine';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { FileService } from 'src/app/shared/services/file/file.service';
import { CampaignModel } from 'src/app/shared/model/campaign.model';

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrl: './create-campaign.component.scss',
})
export class CreateCampaignComponent {
  bannerFile: File | null = null;
  sliderFiles: File[] = [];
  popupVisible = false;
  public campaignModel: CampaignModel = new CampaignModel();

  constructor(
    private readonly fileService: FileService,
    private readonly utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {}

  public onSubmit() {
    const { isValid } = ValidationEngine.validateGroup('validationGrp');
    if (isValid) {
      this.uploadFile(this.bannerFile);
      this.uploadMultipleFiles(this.sliderFiles);
      this.campaignModel.organizer = 'BMQA';
      console.log(this.campaignModel);
      this.popupVisible = true;
    }
  }

  public uploadFile(file): void {
    this.fileService.uploadSingleFile(file).subscribe({
      next: (result) => {
        console.log(result);
        const fileMetadata = {
          url: result[0].url,
          file_id: result[0].file_id,
          resource_id: result[0].resource_id,
        };
        const fileMetadataString = JSON.stringify(fileMetadata);
        console.log(fileMetadata);
        this.campaignModel.appeal_img_url = fileMetadataString;
      },
      error: (err) => {
        // console.log(err);
      },
    });
  }

  public uploadMultipleFiles(files: File[]): void {
    this.fileService.uploadFiles(files).subscribe({
      next: (results) => {
        console.log(results);
        const fileMetadataArray = results.map((result) => ({
          url: result.url,
          file_id: result.file_id,
          resource_id: result.resource_id,
        }));
        const fileMetadataString = JSON.stringify(fileMetadataArray);
        console.log(fileMetadataArray);
        this.campaignModel.img_url = fileMetadataString;
      },
      error: (err) => {
        console.error('Error uploading files:', err);
      },
    });
  }
  // Validate file type
  private isValidFileType(file: File): boolean {
    const allowedTypes = ['image/jpeg', 'image/png'];
    return allowedTypes.includes(file.type);
  }

  // Handle drop event for file upload
  public onDropSuccess(event: DragEvent, fileType: string) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (this.isValidFileType(file)) {
          this.handleFile(file, fileType);
        } else {
          this.utilitiesService.showSwal(
            'warning',
            'warning',
            'Please select a jpg or png file'
          );
        }
      }
    }
  }

  // Handle drag over event
  public onDragOver(event: DragEvent) {
    event.preventDefault();
    const items = event.dataTransfer?.items;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file && !this.isValidFileType(file)) {
            event.dataTransfer.dropEffect = 'none';
            return;
          }
        }
      }
      event.dataTransfer.dropEffect = 'copy';
    }
  }

  // Trigger file input
  public triggerFileInput(inputId: string): void {
    const input = document.getElementById(inputId) as HTMLInputElement;
    if (input) {
      input.click();
    }
  }

  // Handle single file for banner
  onBannerFileChange(event: any) {
    const selectedFile: File = event.target.files[0];
    if (selectedFile && this.isValidFileType(selectedFile)) {
      this.bannerFile = selectedFile;
      console.log(this.bannerFile);
    } else {
      this.utilitiesService.showSwal(
        'warning',
        'warning',
        'Please select a jpg or png file'
      );
    }
  }
  // Handle multiple files for slider
  onSliderFileChange(event: any) {
    const selectedFiles: FileList = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      if (this.isValidFileType(file)) {
        this.sliderFiles.push(file);
        console.log(this.sliderFiles);
      } else {
        this.utilitiesService.showSwal(
          'warning',
          'warning',
          'Please select a jpg or png file'
        );
      }
    }
  }
  // Remove a file from the sliderFiles array
  removeSliderFile(file: File) {
    this.sliderFiles = this.sliderFiles.filter((f) => f !== file);
  }
  removeBannerFile(): void {
    this.bannerFile = null;
    const input = document.getElementById(
      'bannerFileInput'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  private handleFile(file: File, fileType: string) {
    if (fileType === 'banner') {
      this.bannerFile = file;
    } else if (fileType === 'slider') {
      this.sliderFiles.push(file);
    }
  }
}
