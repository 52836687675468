<div class="container-fluid">
  <div class="row flex-nowrap">
    <div
      class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 w-auto"
      style="padding-left: 0px !important"
    >
      <div
        class="d-flex flex-column align-items-start align-items-sm-start px-3 pt-2 min-vh-100 menu_section"
      >
        <a
          class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-decoration-none logo_section"
        >
          <img
            *ngIf="menuOpen"
            class="pointer"
            routerLink="/"
            src="./assets/images/logo/shohay_logo_full.svg"
          />
          <div class="super_admin">
            <div class="super_admin_content">Super Admin</div>
          </div>
          <!-- <img
      class="pointer menu_icon"
      (click)="openCloseMenu()"
      src="./assets/images/icon/menu_icon.svg"
    /> -->
        </a>

        <ul
          class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
          id="menu"
          [ngStyle]="{ width: menuOpen ? '225px !important' : '55px' }"
        >
          <li class="nav-item menu_item_a">
            <a
              routerLink="/admin/workplace"
              class="nav-link align-start px-0 d-flex align-items-center high_height"
              [class.active]="isActive(1)"
              (click)="toggleMenu(1); setActiveIndex(0)"
            >
              <img
                class="icon_padding"
                [src]="
                  isActive(1)
                    ? './assets/images/icon/home_icon_active.svg'
                    : './assets/images/icon/home_icon_inactive.svg'
                "
              />
              <span *ngIf="menuOpen" class="ms-1 d-none d-sm-inline"
                >Workplace</span
              >
            </a>
          </li>
          <!-- <li class="menu_item_a">
      <a
        href="#submenu1"
        data-bs-toggle="collapse"
        class="nav-link px-0 align-middle d-flex align-items-center justify-content-between high_height"
        [class.active]="isActive(2)"
        (click)="toggleMenu(2); setActiveIndex(2)"
      >
        <div class="menu_item">
          <img
            class="icon_padding"
            [src]="
              isActive(2)
                ? './assets/images/icon/learn_icon_active.svg'
                : './assets/images/icon/learn_icon_inactive.svg'
            "
          />
          <div *ngIf="menuOpen" class="ms-1 d-none d-sm-inline">
            Learn
          </div>
        </div>
        <img
          *ngIf="menuOpen"
          [src]="
            open.get(2)
              ? './assets/images/icon/up_arrow_icon_active.svg'
              : './assets/images/icon/down_arrow_icon_inactive.svg'
          "
        />
      </a>
      <ul
        class="collapse multi-collapse nav flex-column ms-1 pl_40"
        id="submenu1"
        data-bs-parent="#menu"
      >
        <li
          class="high_height"
          [class.active2]="isSubmenuActive(21)"
          (click)="setActiveIndex(21); activeIndex = 2"
        >
          <a
            class="nav-link px-0 gap-1"
            [ngClass]="{ sub_menu: isSubmenuActive(21) === false }"
            routerLink="/dashboard/learn-all-ngo"
          >
            <img
              class="icon_padding"
              [src]="
                isSubmenuActive(21)
                  ? './assets/images/icon/workplace_icon_active.svg'
                  : './assets/images/icon/workplace_icon_inactive.svg'
              "
            />
            <span class="d-none d-sm-inline">All NGO</span>
          </a>
        </li>
        <li
          class="high_height"
          [class.active2]="isSubmenuActive(22)"
          (click)="setActiveIndex(22); activeIndex = 2"
        >
          <a
            class="nav-link px-0 gap-1"
            routerLink="/dashboard/learn-news-article"
            [ngClass]="{ sub_menu: isSubmenuActive(22) === false }"
          >
            <img
              class="icon_padding"
              [src]="
                isSubmenuActive(22)
                  ? './assets/images/icon/news_icon_active.svg'
                  : './assets/images/icon/news_icon_inactive.svg'
              "
            />
            <span class="d-none d-sm-inline">News & Articles</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="menu_item_a">
      <a
        class="nav-link px-0 align-middle d-flex align-items-center justify-content-between high_height"
        [class.active]="isActive(3)"
        (click)="toggleMenu(3); setActiveIndex(3)"
      >
        <div class="menu_item">
          <img
            class="icon_padding"
            [src]="
              isActive(3)
                ? './assets/images/icon/donate_icon_active.svg'
                : './assets/images/icon/donate_icon_inactive.svg'
            "
          />
          <div *ngIf="menuOpen" class="ms-1 d-none d-sm-inline">
            Donate
          </div>
        </div>
        <img
          class="icon_padding mr-0"
          style="margin-right: 0px !important"
          src="./assets/images/icon/down_arrow_icon_inactive.svg"
        />
      </a>
    </li>
    <li class="menu_item_a">
      ///////href="#submenu2"/////////
      <a
        href="#submenu2"
        data-bs-toggle="collapse"
        class="nav-link px-0 align-middle d-flex align-items-center justify-content-between high_height"
        [class.active]="isActive(4)"
        (click)="toggleMenu(4); setActiveIndex(4)"
      >
        <div class="menu_item">
          <img
            class="icon_padding"
            [src]="
              isActive(4)
                ? './assets/images/icon/manage_icon_active.svg'
                : './assets/images/icon/manage_icon_inactive.svg'
            "
          />
          <div *ngIf="menuOpen" class="ms-1 d-none d-sm-inline">
            Manage
          </div>
        </div>
        <img
          *ngIf="menuOpen && userNgoList?.length > 0"
          [src]="
            open.get(4)
              ? './assets/images/icon/up_arrow_icon_active.svg'
              : './assets/images/icon/down_arrow_icon_inactive.svg'
          "
        />
      </a>
      <ul
        *ngIf="
          userNgoList?.length > 0 &&
          localStorageService.getNgoInLocalStorage()
        "
        class="collapse multi-collapse nav flex-column ms-1 pl_40"
        id="submenu2"
        data-bs-parent="#menu"
      >
        <li
          class="high_height"
          [class.active2]="isSubmenuActive(41)"
          (click)="setActiveIndex(41); activeIndex = 4"
        >
          <a
            class="nav-link px-0 gap-1"
            [ngClass]="{ sub_menu: isSubmenuActive(41) === false }"
            routerLink="/dashboard/workplace"
          >
            <img
              class="icon_padding"
              [src]="
                isSubmenuActive(41)
                  ? './assets/images/icon/workplace_icon_active.svg'
                  : './assets/images/icon/workplace_icon_inactive.svg'
              "
            />
            <span class="d-none d-sm-inline">Workplace</span>
          </a>
        </li>
        <li
          class="high_height"
          [class.active2]="isSubmenuActive(42)"
          (click)="setActiveIndex(42); activeIndex = 4"
        >
          <a
            class="nav-link px-0 gap-1"
            routerLink="/dashboard/all-projects"
            [ngClass]="{ sub_menu: isSubmenuActive(42) === false }"
          >
            <img
              class="icon_padding"
              [src]="
                isSubmenuActive(42)
                  ? './assets/images/icon/lists_icon_active.svg'
                  : './assets/images/icon/lists_icon_inactive.svg'
              "
            />
            <span class="d-none d-sm-inline">Projects</span>
          </a>
        </li>
        ////////////////<li
          [class.active2]="isSubmenuActive(43)"
          (click)="setActiveIndex(43); activeIndex = 4"
          class="high_height"
        >
          <a
            class="nav-link sub_menu px-0"
            routerLink="/dashboard/create-form"
            [ngClass]="{ sub_menu: isSubmenuActive(43) === false }"
          >
            <img
              class="icon_padding"
              [src]="
                isSubmenuActive(43)
                  ? './assets/images/icon/description_blue.svg'
                  : './assets/images/icon/description.svg'
              "
            />
            <span class="d-none d-sm-inline">Forms</span>
          </a>
        </li> //////////////////////////////////////////////////////////////////////
      </ul>
    </li> -->
          <li class="menu_item_a">
            <a
              href="#submenu3"
              data-bs-toggle="collapse"
              class="nav-link px-0 align-middle d-flex align-items-center justify-content-between high_height"
              [class.active]="isActive(6)"
              (click)="toggleMenu(6); setActiveIndex(6)"
            >
              <div class="menu_item d-flex allign-items-center">
                <img
                  class="icon_padding"
                  [src]="
                    isActive(6)
                      ? './assets/images/icon/user_icon_active.svg'
                      : './assets/images/icon/user_icon_inactive.svg'
                  "
                />
                <div *ngIf="menuOpen" class="ms-1 d-none d-sm-inline">
                  My Profile
                </div>
              </div>
            </a>
          </li>
          <li class="menu_item_a">
            <a
              href="#submenu4"
              data-bs-toggle="collapse"
              class="nav-link px-0 align-middle d-flex align-items-center justify-content-between high_height"
              [class.active]="isActive(8)"
              (click)="toggleMenu(8); setActiveIndex(8)"
            >
              <div class="menu_item">
                <img
                  class="icon_padding"
                  [src]="
                    isActive(8)
                      ? './assets/images/icon/settings_icon_active.svg'
                      : './assets/images/icon/settings_icon_inactive.svg'
                  "
                />
                <div *ngIf="menuOpen" class="ms-1 d-none d-sm-inline">
                  Account Settings
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col py-3 dashboard_body">
      <app-super-admin-header></app-super-admin-header>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
