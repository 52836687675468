export class CampaignModel {
  title: string = null;
  description: string = null;
  img_url: string = null;
  appeal_title: string = null;
  appeal_subtitle: string = null;
  appeal_img_url: string = null;
  start_date: Date = null;
  end_date: Date = null;
  organizer: string = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}
