import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-super-admin-header',
  templateUrl: './super-admin-header.component.html',
  styleUrl: './super-admin-header.component.scss'
})
export class SuperAdminHeaderComponent implements OnInit {
  public userName: string;
  public userNameFirstLetter: string;
  public currentUrl: string;

  constructor(
    public readonly router: Router,
    public readonly authService: AuthService,
    public readonly utilitiesService: UtilitiesService,
    public readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.userName = this.localStorageService.getSessionUser()?.name;
    this.userNameFirstLetter = this.userName.charAt(0);
    //this.getCurrentUrl();
  }

  private getCurrentUrl(): void {
    const value = this.router.url;
    // if (value.includes('bookmarked-ngos')) {
    //   this.currentUrl = 'Bookmarked NGOs';
    // }
  }

  public logOut(): void {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }
}
