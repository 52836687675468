import { Injectable } from '@angular/core';
import { UrlEnum } from '../../enum/url.enum';
import { UtilitiesService } from '../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private fileUploadApi = `${UrlEnum.SHOHAY_FILE}/aws-bucket-files`;
  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}
  public uploadFiles(files) {
    const formData = new FormData();
    // formData.append('Content-Type', 'multipart/form-data');
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    return this.httpRequestService
      .post(`${this.fileUploadApi}/upload/multiple`, formData)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
  public uploadSingleFile(file) {
    const formData = new FormData();
    // formData.append('Content-Type', 'multipart/form-data');
    formData.append('files', file);
    return this.httpRequestService
      .post(`${this.fileUploadApi}/upload/multiple`, formData)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
