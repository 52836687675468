import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevExtremeModule, DxTabPanelModule } from 'devextreme-angular';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { SuperAdminRoutingModule } from './super-admin.routing.module';
import { SuperAdminMenuComponent } from 'src/app/layout/super-admin-layout/super-admin-menu/super-admin-menu.component';
import { SuperAdminHeaderComponent } from 'src/app/layout/super-admin-layout/super-admin-header/super-admin-header.component';
import { RouterModule } from '@angular/router';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { SAdminWorkplaceComponent } from './s-admin-workplace/s-admin-workplace.component';
import { CreateCampaignComponent } from './create-campaign/create-campaign.component';

@NgModule({
  declarations: [
    SuperAdminHeaderComponent,
    SuperAdminMenuComponent,
    CampaignListComponent,
    CreateCampaignComponent,
    SAdminWorkplaceComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    SuperAdminRoutingModule,
    DevExtremeModule,
    FormsModule,
    SharedModule,
  ],
})
export class SuperAdminModule {}
