import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CampaignsService } from 'src/app/shared/services/campaign/campaigns.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrl: './campaign-list.component.scss',
})
export class CampaignListComponent {
  public allCampaign: any = [];
  public recentCampaign: any = [];
  public favCampaign: any = [];
  private tabIcons = [
    {
      inactive: '../../../../../assets/images/icon/schedule_icon_active.svg',
      active: '../../../../../assets/images/icon/schedule_icon.svg',
    }, // Recent
    {
      inactive: '../../../../../assets/images/icon/description_inactive.svg',
      active: '../../../../../assets/images/icon/description_blue.svg',
    }, // My campaigns
    {
      inactive: '../../../../../assets/images/icon/kid_star_icon.svg',
      active: '../../../../../assets/images/icon/kid_star_icon_active.svg',
    }, // Favorite
  ];

  public activeTab = 0;
  public activeTabIndex = 0;
  public searchKeyword = '';

  constructor(
    public router: Router,
    public spinner: NgxSpinnerService,
    public campaignService: CampaignsService,
    public utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {
    this.getAllCampaign();
  }

  private getAllCampaign() {
    this.allCampaign = [];
    this.spinner.show();
    this.campaignService.getAllCampaigns().subscribe({
      next: (result) => {
        this.spinner.hide();
        this.allCampaign = result;
        this.recentCampaign = result;
        //console.log(this.allCampaign);
      },
      error: (err) => {
        this.spinner.hide();
        // console.log(err);
      },
    });
  }

  public buttonClick(e: any) {
    switch (e.itemData) {
      case 'Stop Campaign':
        break;
      case 'Edit':
        break;
      case 'Delete':
        break;
      case 'Share':
        break;
      default:
        break;
    }
  }

  public get activeDataSource() {
    const filterDataSource = this.getCurrentDataSource();
    return this.filterDataSource(filterDataSource);
  }

  public getCurrentDataSource() {
    switch (this.activeTab) {
      case 0:
        return this.recentCampaign;
      case 1:
        return this.allCampaign;
      case 2:
        return this.favCampaign;
      default:
        return [];
    }
  }

  private filterDataSource(dataSource: any[]) {
    if (!this.searchKeyword) {
      return dataSource;
    }
    const keyword = this.searchKeyword.toLowerCase();
    return dataSource.filter((campaign) =>
      campaign.appeal_title.toLowerCase().includes(keyword)
    );
  }

  public onTabChange(event: any) {
    this.activeTab = event.component.option('selectedIndex');
    this.activeTabIndex = event.itemIndex;
  }

  public getIcon(index: number): string {
    return this.activeTab === index
      ? this.tabIcons[index].active
      : this.tabIcons[index].inactive;
  }

  public onSearchKeywordChanged(event: any) {
    console.log(this.searchKeyword);
    this.searchKeyword = event.event.target.value;
  }

  public formatDate(date: string): string {
    return this.utilitiesService.formattedDateddMMyyyy(
      date,
      'dd-mmm-yyyy',
      ' '
    );
  }
}
