import { Component } from '@angular/core';

@Component({
  selector: 'app-s-admin-workplace',
  templateUrl: './s-admin-workplace.component.html',
  styleUrl: './s-admin-workplace.component.scss'
})
export class SAdminWorkplaceComponent {

}
