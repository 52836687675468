import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from 'src/app/shared/components/not-found/not-found.component';
import { SAdminWorkplaceComponent } from './s-admin-workplace/s-admin-workplace.component';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { CreateCampaignComponent } from './create-campaign/create-campaign.component';

const routes: Routes = [
  {
    path: 'campaign-list',
    component: CampaignListComponent,
    pathMatch: 'full',
  },
  {
    path: 'create-new-campaign',
    component: CreateCampaignComponent,
    pathMatch: 'full',
  },
  {
    path: 'workplace',
    component: SAdminWorkplaceComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: 'workplace',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuperAdminRoutingModule {}
