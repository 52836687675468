<div class="main_div">
  <div class="breadcrumb">
    <span class="operation_text">Operations </span>
    <span> / Donation Campaigns </span>
    <span> / Create</span>
  </div>
  <div class="header">Create Campaign</div>
  <div class="row" style="gap: 32px">
    <div class="campaign_form_div col">
      <!-- Banner Information -->
      <div class="Banner_information_div">
        <div class="contact_title">Banner Information</div>
        <div class="form_div_campaign">
          <label class="field_label requiredMark">Name of Campaign</label>
          <dx-text-box
            class="input_field"
            placeholder="  e.g; Wounded Students Assistance Fund - 2024"
            [elementAttr]="{ class: 'all-height' }"
            [showClearButton]="true"
            [(ngModel)]="campaignModel.appeal_title"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Name of Campaign is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
          <label class="field_label requiredMark">Banner Title</label>
          <dx-text-box
            class="input_field"
            placeholder="e.g; Aid appeal for wounded students"
            [elementAttr]="{ class: 'all-height' }"
            [showClearButton]="true"
            [(ngModel)]="campaignModel.appeal_subtitle"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Banner title is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
          <label class="field_label requiredMark">Banner File</label>
          <div class="banner_file_upload_container">
            <div
              class="file-upload-container input_field"
              (drop)="onDropSuccess($event, 'banner')"
              (dragover)="onDragOver($event)"
            >
              <label *ngIf="!bannerFile">
                <span class="box-dragndrop">
                  Drag and drop files here or browse files</span
                >
              </label>
              <input
                *ngIf="!bannerFile"
                #bannerFileInput
                type="file"
                name="bannerFile"
                id="bannerFileInput"
                class="box-file"
                aria-hidden="true"
                accept=".jpg, .jpeg, .png"
                (change)="onBannerFileChange($event)"
              />
              <button
                *ngIf="!bannerFile"
                class="blue-button-md"
                style="margin-right: 5px"
                type="button"
                (click)="triggerFileInput('bannerFileInput')"
              >
                Browse Files
              </button>
              <div *ngIf="bannerFile" class="uploaded-file-list">
                <div class="upload-file">
                  <span class="box-dragndrop">{{ bannerFile.name }}</span>
                  <button
                    type="button"
                    class="remove-file-button"
                    (click)="removeBannerFile()"
                  >
                    <img src="../../../../assets/images/icon/cross-icon.svg" />
                  </button>
                </div>
              </div>
            </div>
            <div class="file-format-required">
              Note: Note: File should be inJPEG, or PNG format and size should
              be minimum 624x312px
            </div>
          </div>
        </div>
      </div>
      <!-- Campaign Information -->
      <div class="campaign_information_div" style="margin-top: 50px">
        <div class="mb-3 contact_title">Campaign Information</div>
        <div class="form_div_campaign">
          <label class="field_label requiredMark">Title of Campaign</label>
          <dx-text-box
            class="input_field"
            placeholder=" e.g; Independence 2.0 Wounded Students Assistance Fund"
            [elementAttr]="{ class: 'all-height' }"
            [showClearButton]="true"
            [(ngModel)]="campaignModel.title"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Title of Campaign is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
          <div class="date-container row">
            <div class="col">
              <label class="field_label mb-2 requiredMark">Start Date</label>
              <dx-date-box
                placeholder="dd-mm-yyy"
                [type]="'date'"
                [elementAttr]="{ class: 'all-height' }"
                class="all-height custom-date-box"
                [(ngModel)]="campaignModel.start_date"
              >
                <dx-validator validationGroup="validationGrp">
                  <dxi-validation-rule
                    type="required"
                    message="Date is required"
                  >
                  </dxi-validation-rule>
                </dx-validator>
              </dx-date-box>
            </div>
            <div class="col">
              <label class="field_label mb-2 requiredMark">End Date</label>
              <dx-date-box
                placeholder="dd-mm-yyyy"
                [type]="'date'"
                [elementAttr]="{ class: 'all-height' }"
                class="custom-date-box"
                [(ngModel)]="campaignModel.end_date"
              >
                <dx-validator validationGroup="validationGrp">
                  <dxi-validation-rule
                    type="required"
                    message="Date is required"
                  >
                  </dxi-validation-rule>
                </dx-validator>
              </dx-date-box>
            </div>
          </div>
          <div class="text_area_container">
            <label class="field_label requiredMark mb-1">Description</label>
            <dx-text-area
              class="text_area_field"
              placeholder="e.g; Write about campaign in details"
              [(ngModel)]="campaignModel.description"
            >
              <dx-validator validationGroup="validationGrp">
                <dxi-validation-rule
                  type="required"
                  message="You must write Write about campaign in details"
                >
                </dxi-validation-rule>
              </dx-validator>
            </dx-text-area>
            <div>Note: Maximum 200 words</div>
          </div>
          <label class="field_label requiredMark">Slider Files</label>
          <div class="banner_file_upload_container">
            <div
              class="file-upload-container input_field"
              (drop)="onDropSuccess($event, 'slider')"
              (dragover)="onDragOver($event)"
            >
              <label>
                <span class="box-dragndrop">
                  Drag and drop files here or browse files</span
                >
              </label>
              <input
                #sliderFileInput
                type="file"
                name="sliderFiles[]"
                id="sliderFileInput"
                class="box-file"
                aria-hidden="true"
                accept=".jpg, .jpeg, .png"
                multiple
                (change)="onSliderFileChange($event)"
              />
              <button
                class="blue-button-md"
                style="margin-right: 5px"
                type="button"
                (click)="triggerFileInput('sliderFileInput')"
              >
                Browse Files
              </button>
            </div>
            <div class="d-flex flex-column">
              <div class="file-format-required">
                Note: Note: File should be inJPEG, or PNG format and size should
                be minimum 624x312px
              </div>
              <div *ngIf="sliderFiles.length > 0" class="uploaded-file-list">
                <div *ngFor="let file of sliderFiles" class="upload-file">
                  <span class="box-dragndrop" style="margin-top: 5px">{{
                    file.name
                  }}</span>
                  <button
                    type="button"
                    (click)="removeSliderFile(file)"
                    class="remove-file-button"
                  >
                    <img src="../../../../assets/images/icon/cross-icon.svg" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 50px; margin-bottom: 50px">
        <button class="blue-button-lg" (click)="onSubmit()">
          Create Campaign
        </button>
      </div>
    </div>
    <!-- Contact Information Form (Read-only) -->
    <div class="contact_information col" style="width: 600px">
      <div class="contact_title">Contact information form</div>
      <div style="margin-top: 12px; margin-bottom: 20px">
        <img src="" />
        <span class="not_edited">Can't be edited</span>
      </div>
      <div
        class="form_div_parent d-flex justify-content-center bg-white"
        style="max-width: 598px"
      >
        <div class="form_div_donation d-flex flex-column">
          <div class="form_header">Donation Form</div>
          <div class="form_text text-center">
            Please complete the donation form, and our team will get in touch
            with you shortly.
          </div>
          <div class="d-flex flex-column" style="gap: 24px">
            <label class="field_label requiredMark">Your Name</label>
            <dx-text-box
              class="input_field"
              placeholder="Enter your name here"
              [readOnly]="true"
            >
              <dx-validator validationGroup="validationGrp"></dx-validator>
            </dx-text-box>
          </div>
          <div class="d-flex flex-column" style="gap: 24px">
            <label class="field_label requiredMark">Email</label>
            <dx-text-box
              class="input_field"
              placeholder="Enter your email address here"
              [readOnly]="true"
            >
              <dx-validator validationGroup="validationGrp"></dx-validator>
            </dx-text-box>
          </div>
          <div class="d-flex flex-column" style="gap: 24px">
            <label class="field_label requiredMark">Phone</label>
            <dx-text-box
              class="input_field"
              placeholder="Enter phone number here"
              [readOnly]="true"
            >
              <dx-validator validationGroup="validationGrp"></dx-validator>
            </dx-text-box>
          </div>
          <div class="d-flex flex-column" style="gap: 24px">
            <label class="field_label requiredMark">Donation Amount</label>
            <dx-number-box
              class="input_field"
              placeholder="Enter your donation amount here"
              [readOnly]="true"
            >
              <dx-validator validationGroup="validationGrp"></dx-validator>
            </dx-number-box>
          </div>
          <div>
            <button class="blue-button-lg w-100 mt-5" [disabled]="true">
              Submit Donation
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <dx-popup
    class="submit_create"
    [(visible)]="popupVisible"
    [maxWidth]="600"
    [maxHeight]="500"
    [showTitle]="true"
    [showCloseButton]="false"
    [dragEnabled]="true"
    [resizeEnabled]="true"
    [dragEnabled]="true"
    position="center"
  >
    <div class="submit_create_popup">
      <div class="created_div d-flex flex-column">
        <img
          style="height: 96px; width: 96px"
          src="../../../../../assets/images/icon/icons8-check-mark-96 1.svg"
          alt="form-options"
          title="form-options"
        />
        <span class="success_text">Your campaign has been created</span>
        <div class="button_container d-flex">
          <button class="blue-button-lg">View Campaign</button>
          <button
            class="yellow-button-lg"
            style="margin-left: 12px"
            routerLink="/dashboard/campaign-list"
          >
            Back to Campaigns
          </button>
        </div>
      </div>
    </div>
  </dx-popup>
</div>
