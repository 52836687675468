import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';

@Component({
  selector: 'app-super-admin-menu',
  templateUrl: './super-admin-menu.component.html',
  styleUrl: './super-admin-menu.component.scss',
})
export class SuperAdminMenuComponent implements OnInit {
  public activeIndex: number;
  public activeSubmenuIndex: number;
  menuOpen: boolean = true;
  public open = new Map<number, boolean>([
    [2, false],
    [4, false],
    [6, false],
  ]);
  public isOpen = false;
  public sessionUser: any;

  constructor(
    private readonly router: Router,
    public readonly localStorageService: LocalStorageService,
  ) {}

  public ngOnInit(value?: any): void {
    const aclMenu = value || this.getACLFromUrl();
    this.activeIndex = aclMenu?.menu_index;
  }

  public toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  public toggleMenu(index: number): void {
    this.activeIndex = index;
    if (this.open.has(index)) {
      this.toggleOpen(index);
    }
  }

  public isActive(index: number): boolean {
    return this.activeIndex === index;
  }
  public isSubmenuActive(index: number): boolean {
    return this.activeSubmenuIndex === index;
  }
  public setActiveIndex(index: number): void {
    this.activeSubmenuIndex = index;
  }

  private toggleOpen(index: number): void {
    const value = this.open.get(index);
    this.open.forEach((value, key) => {
      this.open.set(key, false);
    });
    this.open.set(index, !value);
  }

  public openCloseMenu(): void {
    // this.menuOpen = !this.menuOpen;
  }

  private getACLFromUrl(): any {
    const value = this.router.url;
    if (value.includes('workplace')) {
      return { menu_name: 'Workplace', menu_index: 1 };
    }
  }
}
